import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import QS from 'query-string';

class Home extends Component {
  constructor() {
    super();
  }
  
  componentWillMount() {
  }

  render() {
    const queryParams = QS.parse(this.props.location.search);
    return (
      <div className="">
        Hello
        {
          Object.keys(queryParams).map((key) => {
            console.log('key', key)
            return (
              <div>
                {key}: {queryParams[key]}
              </div>
            )
          })
        }
      </div>
    )
  }
}


export default connect(
  (state) => {
    
  }
)(Home)
